import { useState } from "react";
import axios from "axios";
import styles from "../../styles/components/home/EarlyAccess.module.css";
import { toast } from "react-toastify";

const EarlyAccess = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    profession: "",
    termsAccepted: false,
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);

  // Regex patterns
  const nameRegex = /^[A-Za-z\s]+$/;
  const phoneRegex = /^[0-9]*$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Handle input change with validation
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let error = "";

    // Validate inputs
    if (value.trim() === "" && name !== "phone") {
      error = "This field is required.";
    } else {
      switch (name) {
        case "firstName":
        case "lastName":
        case "city":
        case "profession":
          if (!nameRegex.test(value)) error = "This field should contain letters only.";
          break;
        case "phone":
          if (!phoneRegex.test(value)) error = "This field accepts digits only.";
          break;
        case "email":
          if (!emailRegex.test(value)) error = "Please enter a valid email address.";
          break;
        default:
          break;
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.termsAccepted) {
      alert("You must accept the Terms of Service before proceeding.");
      return;
    }

    // Check if any errors exist before submitting
    for (const key in formData) {
      if (formData[key].trim() === "" && key !== "phone") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: "This field is required.",
        }));
      }
    }

    if (Object.values(errors).some((err) => err !== "")) {
      return;
    }

    setIsSubmitting(true);

    try {
      await axios.post(
        "https://tosyncupserver-y32i.onrender.com/api/early-access/",
        formData
      );
      setIsFlipped(true);
      toast.success("Form Submitted Successfully!");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          toast.info("You are already subscribed!");
        } else {
          toast.error(
            error.response.data?.message || "Error submitting form. Please try again."
          );
        }
      } else {
        toast.error("Network error. Please check your connection and try again.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className={styles.earlyAccess} id="early-access">
      <div className={styles.decorLineContainer}>
        <div className={styles.leftLine}></div>
        <img
          src="/assets/images/tosyncup.png"
          alt="SyncUp"
          className={styles.syncupIcon}
        />
        <div className={styles.rightLine}></div>
      </div>

      <h2 className={styles.heading}>
        Your Passport To Powerful Connections Around The World.
      </h2>

      {!isFlipped ? (
        <div className={`${styles.formContainer} ${isFlipped ? styles.flipped : ""}`}>
          <h3 className={styles.formHeading}>Get Early Access</h3>
          <img
            src="/assets/images/tosyncup.png"
            alt="SyncUp"
            className={styles.syncupLogo}
          />
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.row}>
              <div className={styles.inputGroup}>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name *"
                  value={formData.firstName}
                  onChange={handleChange}
                  className={errors.firstName ? styles.errorInput : ""}
                />
                {errors.firstName && <p className={styles.errorText}>{errors.firstName}</p>}
              </div>
              <div className={styles.inputGroup}>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name *"
                  value={formData.lastName}
                  onChange={handleChange}
                  className={errors.lastName ? styles.errorInput : ""}
                />
                {errors.lastName && <p className={styles.errorText}>{errors.lastName}</p>}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.inputGroup}>
                <input
                  type="email"
                  name="email"
                  placeholder="Email ID *"
                  value={formData.email}
                  onChange={handleChange}
                  className={errors.email ? styles.errorInput : ""}
                />
                {errors.email && <p className={styles.errorText}>{errors.email}</p>}
              </div>
              <div className={styles.inputGroup}>
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  className={errors.phone ? styles.errorInput : ""}
                />
                {errors.phone && <p className={styles.errorText}>{errors.phone}</p>}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.inputGroup}>
                <input
                  type="text"
                  name="city"
                  placeholder="Current City *"
                  value={formData.city}
                  onChange={handleChange}
                  className={errors.city ? styles.errorInput : ""}
                />
                {errors.city && <p className={styles.errorText}>{errors.city}</p>}
              </div>
              <div className={styles.inputGroup}>
                <input
                  type="text"
                  name="profession"
                  placeholder="Profession *"
                  value={formData.profession}
                  onChange={handleChange}
                  className={errors.profession ? styles.errorInput : ""}
                />
                {errors.profession && <p className={styles.errorText}>{errors.profession}</p>}
              </div>
            </div>

            <div className={styles.terms}>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  name="termsAccepted"
                  checked={formData.termsAccepted}
                  onChange={handleChange}
                />
                <span className={styles.slider}></span>
              </label>
              <span className={styles.termsText}>
                I agree to the Terms Of Service
              </span>
            </div>

            <button
              type="submit"
              className={styles.submitButton}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      ) : (
        <div className={styles.successContainer}>
          <img
            src="/assets/images/tosyncup.png"
            alt="SyncUp"
            className={styles.successLogo}
          />
          <h3 className={styles.successHeading}>Thank you for signing up!</h3>
          <p className={styles.successText}>We will notify you once we launch.</p>
        </div>
      )}
    </section>
  );
};

export default EarlyAccess;