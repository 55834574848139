import React from "react";
import styles from "../../styles/components/home/Offering.module.css";
import { motion } from "framer-motion";

const offerings = [
  { title: "Elite Matchmaking", description: "Personalized connections for high-profile users", image: "/assets/images/OFFR_img1.jpg" },
  { title: "VIP Networking", description: "Exclusive social circles & professional meets", image: "/assets/images/OFFR_img2.jpg" },
  { title: "Concierge Services", description: "Luxury bookings, events, & travel planning", image: "/assets/images/OFFR_img3.png" },
  { title: "Verified Memberships", description: "Ensuring authenticity & elite standards", image: "/assets/images/OFFR_img4.avif" },
  { title: "Premium Events", description: "Invite-only parties & networking galas", image: "/assets/images/OFFR_img5.jpeg" },
  { title: "AI-Powered Introductions", description: "Smart algorithms for perfect matches", image: "/assets/images/OFFR_img6.webp" },
];

const Offering = () => {
  return (
    <section className={styles.container}>
      <div className={styles.titleWrapper}>
        <h2 className={styles.title}>Our Offerings</h2>
      </div>
      <div className={styles.decorLineContainer}>
        <div className={styles.leftLine}></div>
        <img src="/assets/images/tosyncup.png" alt="SyncUp" className={styles.syncupIcon} />
        <div className={styles.rightLine}></div>
      </div>
      
      <motion.div 
        className={styles.grid}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {offerings.map((offering, index) => (
          <motion.div
            key={index}
            className={styles.card}
            whileHover={{ scale: 1 }} // No scaling
            whileTap={{ scale: 0.98 }}
          >
            <img src={offering.image} alt={offering.title} className={styles.image} />
            <div className={styles.overlay}>
              <div className={styles.overlayContent}>
                <h3 className={styles.text}>{offering.title}</h3>
                <p className={styles.description}>{offering.description}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </motion.div>
      <button className={styles.button} onClick={() => {
        document.getElementById('early-access')?.scrollIntoView({ behavior: 'smooth' });
      }}>JOIN</button>
    </section>
  );
};

export default Offering;