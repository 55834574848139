import { FaApple } from "react-icons/fa6";
import { BiLogoPlayStore } from "react-icons/bi";

import styles from "../../styles/components/home/Banner.module.css";

const Banner = () => {
  return (
    <section className={styles.banner}>
      <div className={styles.content}>  
        <h1 className={styles.heading}>The Few Who Belong,</h1>
        <h1 className={styles.highlightedText}>Already Know.</h1>
        <div className={styles.launchSection}>
          <p>Launching soon</p>
          <div className={styles.launchIcons}>
            <FaApple className={styles.storeIcon} />
            <BiLogoPlayStore className={styles.storeIcon} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
