import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styles from "../../styles/components/home/Hero.module.css";

const Hero = () => {
  const images = [
    "https://media.istockphoto.com/id/1682296067/photo/happy-studio-portrait-or-professional-man-real-estate-agent-or-asian-businessman-smile-for.jpg?s=612x612&w=0&k=20&c=9zbG2-9fl741fbTWw5fNgcEEe4ll-JegrGlQQ6m54rg=",
    "https://images.pexels.com/photos/1081685/pexels-photo-1081685.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5NTBNBrHAvaW_hEYgujDAUqbLQ1j0R22ANw&s",
    "https://images.unsplash.com/photo-1575936123452-b67c3203c357?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRGCg4pLQ1ckWPPMqf4s4eLyiKKMUU9bpjtA&s",
  ];

  const [activeIndex, setActiveIndex] = useState(2);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prev) => (prev + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  const getTranslateX = (position, width) => {
    if (width <= 768) return 0;
    if (width <= 992) return position * 180;
    if (width <= 1200) return position * 210;
    return position * 240;
};

  return (
    <section className={styles.heroContainer}>
      <div className={styles.header}>
        <div className={styles.logo}>Sync Up</div>
        <button className={styles.earlyAccess} onClick={() => {
          document.getElementById('early-access')?.scrollIntoView({ behavior: 'smooth' });
        }}>Get Early Access</button>
      </div>
      <div className={styles.decorLineContainer}>
        <div className={styles.leftLine}></div>
        <img
          src="/assets/images/tosyncup.png"
          alt="SyncUp"
          className={styles.syncupIcon}
        />
        <div className={styles.rightLine}></div>
      </div>
      <div className={styles.carouselWrapper}>
        <div className={styles.carousel}>
          {images.map((img, index) => {
            const position = (index - activeIndex + images.length) % images.length - 2;
            const isMobile = window.innerWidth <= 768;
            const translateX = getTranslateX(position, window.innerWidth);
            const translateY = isMobile ? (position < 0 ? position * 40 : 0) : 0;
            const isActive = position === 0;
            const isAdjacent = Math.abs(position) === 1;
            const scale = isMobile ? (isActive ? 1 : 0.9) : isActive ? 1.2 : isAdjacent ? 1 : 0.8;
            const zIndex = isMobile ? images.length - Math.abs(position) : isActive ? 3 : isAdjacent ? 2 : 1;
            const opacity = isMobile ? (isActive ? 1 :isAdjacent? 1 : 1) : isActive ? 1 : isAdjacent ? 1 : 1;
            const rotateY = isActive ? 0 : position * 10;
            return (
              <motion.div
                key={index}
                className={`${styles.card} ${isActive ? styles.active : isAdjacent ? styles.adjacent : ""}`}
                initial={{ opacity: 0.6, scale: 0.9 }}
                animate={{ duration: 2, opacity, scale, x: translateX, y: translateY, rotateY, zIndex }}
                transition={{ duration: 0, ease: "easeInOut" }}
              >
                <img src={img} alt={`slide-${index}`} className={styles.image} />
              </motion.div>
            );
          })}
        </div>
        <h1 className={styles.heroText}>
          Meet the <span className={styles.highlight}>Highly Admired</span>, Beyond Borders.
        </h1>
      </div>
    </section>
  );
};

export default Hero;