import styles from "../../styles/components/home/Footer.module.css";
import {  FaXTwitter } from "react-icons/fa6";
import { FaBluesky } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import { FaLinkedinIn } from "react-icons/fa";



const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.socialIcons}>
          <FaLinkedinIn className={styles.icon} />
          <RiInstagramFill className={styles.icon} />
          <FaBluesky className={styles.icon} />
          <FaXTwitter className={styles.icon} />
        </div>
        <div className={styles.bottomSection}>
          <p className={styles.copyright}>
          <img src="/assets/images/tosyncup.png" alt="syncup logo" className={styles.logo}/>

            {" "} <span className={styles.syncup}>Sync Up</span>
             <p className={styles.copyrightText}> Ⓒ JOMS Commerce & Technologies Pvt. Ltd 2025. All rights reserved.</p>
          </p>
          <div className={styles.policyLinks}>
            <a href="https://joms.in/termsofservice" className={styles.link} rel="noopener">Terms Of Service</a>
            <a href="https://joms.in/privacypolicy" className={styles.link} rel="noopener">Privacy Policy</a>
            <a href="https://joms.in/cookiespolicy" className={styles.link} rel="noopener">Cookies Policy</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
