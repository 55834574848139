import React, { useEffect, useState } from 'react'
import Hero from '../../components/home/Hero'
import styles from '../../styles/pages/main/Home.module.css'
import Banner from '../../components/home/Banner';
import Footer from '../../components/home/Footer';
import EarlyAccess from '../../components/home/EarlyAccess';
import Offering from '../../components/home/Offering';

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      if (isScrolling) return;

      event.preventDefault();
      setIsScrolling(true);

      const sections = document.querySelectorAll(".page");
      let newIndex = currentIndex;

      if (event.deltaY > 0) {
        if (currentIndex < sections.length - 1) newIndex = currentIndex + 1;
      } else if (event.deltaY < 0) {
        if (currentIndex > 0) newIndex = currentIndex - 1;
      }

      setCurrentIndex(newIndex);
      window.scrollTo({ top: newIndex * window.innerHeight, behavior: "smooth" });

      setTimeout(() => setIsScrolling(false), 800);
    };

    window.addEventListener("wheel", handleScroll, { passive: false });

    return () => window.removeEventListener("wheel", handleScroll);
  }, [currentIndex, isScrolling]);
  return (
    <div className={styles.homeContainer}>
      <Hero/>
      <Offering/>
      <EarlyAccess/>
      <Banner/>
      <Footer />
    </div>
  )
}

export default Home
